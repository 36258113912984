import React from "react"
import { graphql } from "gatsby"
import { Box, Flex } from "@singita/components"
import { Link } from "gatsby"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import RichTextRenderer from "../components/RichTextRenderer"
import {
  Meta,
  Heading,
  ActionBar,
  Text,
  GridContainer,
  Section,
  Card,
  Container,
  Divider,
  Author,
} from "@singita/components"
import {
  generateBlogSlug,
  getMetaDescription,
  createSlug,
} from "../common/utils"
import SocialSharing from "../components/SocialSharing"
import HeroWrapper from "../modules/HeroWrapper"
import GridItem from "../components/GridItem"
import Main from "gatsby-singita-theme/src/components/Main"
import { useActionBar, useSeo } from "gatsby-singita-theme/src/hooks"
import HelmetWrapper, {
  getJsonLd,
} from "gatsby-singita-theme/src/components/Helmet"
import {
  TEMPLATE_TYPES,
  ARTICLE_TYPES,
} from "gatsby-singita-theme/src/common/constants"
import ImageWrapper from "../modules/ImageWrapper"

const ARTICLE_TYPE_MAP = {
  [ARTICLE_TYPES.BLOG]: "blog",
  [ARTICLE_TYPES.WILDLIFE]: "wildlife",
  [ARTICLE_TYPES.PRESS]: "press-room",
}

const DATA_MAP = {
  title: ({ type, headline, date }) => {
    return type === "Wildlife Report" ? `${headline}: ${date}` : headline
  },
  description: ({ content, description }) => {
    if (description?.description) {
      return description.description
    } else if (content?.raw) {
      return getMetaDescription(JSON.parse(content.raw))
    } else {
      return null
    }
  },
  image: ["image", "fluid", "src"],
  altText: ["image", "description"],
  publishDate: ["publishDate"],
  author: ["author", "name"],
  type: ["type"],
  categories: ({ categories }) => {
    return categories ? categories.map(({ name }) => name).join(", ") : null
  },
  breadcrumb: ({ type }) => {
    return type ? ARTICLE_TYPE_MAP[type] : null
  },
}

function renderRelatedPost(post, index) {
  return (
    <GridItem index={index} columns={[1, 1, 2, 3]}>
      <Link to={generateBlogSlug(post.slug, post.year, post.month)}>
        <Card
          variant="standard"
          isLink={true}
          headline={post.name}
          meta={[
            { text: post.date, color: "textDark" },
            ...(post.categories && post.categories.length > 0
              ? [{ text: post.categories[0].name, color: "brandBrown" }]
              : []),
          ]}
          image={<ImageWrapper image={post.image} />}
        />
      </Link>
    </GridItem>
  )
}

const ArticleTemplate = props => {
  const {
    data: { contentfulArticles, fallbackRelatedArticles },
  } = props
  const {
    headline,
    date,
    hero,
    categories,
    content,
    relatedArticles,
    structuredData,
    author,
  } = contentfulArticles

  const [actionbarRef, isMobile] = useActionBar()
  const seoProps = useSeo(contentfulArticles, DATA_MAP)
  const jsonLd = getJsonLd({ ...seoProps }, TEMPLATE_TYPES.ARTICLE)

  const hasRelatedArticles = relatedArticles && relatedArticles.length > 0
  const hasFallbackArticles =
    fallbackRelatedArticles.edges && fallbackRelatedArticles.edges.length > 0

  return (
    <Main {...props} codes={{}}>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd} />
      {hero && hero.image && (
        <HeroWrapper imageRef={hero.image} subtitle={hero.caption} />
      )}
      <Box maxWidth="700px" mx="auto" pt={[10]} pb={[2]} px={[2]}>
        <Box mb={[3]}>
          <Meta
            meta={[
              { text: date, color: "textDark" },
              ...(categories || []).map(({ headline, hasPage }) => ({
                text: headline,
                color: "brandBrown",
                type: hasPage ? "link" : null,
                to: hasPage ? `/blog/${createSlug(headline)}/` : null,
              })),
            ]}
            size="body"
          />
        </Box>
        <ActionBar
          ref={actionbarRef}
          headline={
            isMobile
              ? null
              : structuredData
              ? structuredData.headline
              : headline
          }
          description={
            isMobile || !categories ? null : `in ${categories[0].headline}`
          }
          isSticky={true}
        >
          <Flex alignItems="center">
            {!isMobile && (
              <Box mr={[2]}>
                <Text ml={[0, 2]} size="small">
                  Share:
                </Text>
              </Box>
            )}
            {seoProps && <SocialSharing {...seoProps} />}
          </Flex>
        </ActionBar>
        <Box mb={[4]}>
          <Heading size="h1" as="h1">
            {props.pageContext.type === "Wildlife Report"
              ? `${headline}: ${date}`
              : headline}
          </Heading>
        </Box>
        <RichTextRenderer content={content} />
        {author && (
          <Link to={`/author/${createSlug(author.name)}/`}>
            <Author
              {...author}
              image={
                author.image ? (
                  <Box width={100}>
                    <GatsbyImage fluid={author.image.fluid} />
                  </Box>
                ) : null
              }
            />
          </Link>
        )}
      </Box>
      {hasRelatedArticles || hasFallbackArticles ? (
        <Container>
          <Divider mb={[4]} sx={{ opacity: 0.5 }} />
          <Box pb={[10]}>
            <Section headline={`Related Stories`}>
              <GridContainer
                sx={{
                  gridTemplateColumns: [
                    "1fr",
                    "1fr",
                    "repeat(2, 1fr)",
                    "repeat(3, 1fr)",
                  ],
                  gap: [4],
                }}
              >
                {hasRelatedArticles
                  ? relatedArticles.map(renderRelatedPost)
                  : fallbackRelatedArticles.edges.map(({ node }, index) =>
                      renderRelatedPost(node, index)
                    )}
              </GridContainer>
            </Section>
          </Box>
        </Container>
      ) : null}
    </Main>
  )
}

export default ArticleTemplate

export const ArticleQuery = graphql`
  fragment RelatedArticle on ContentfulArticles {
    id
    name
    slug
    date: publishDate(formatString: "MMMM YYYY")
    month: publishDate(formatString: "MM")
    year: publishDate(formatString: "YYYY")
    author {
      name
    }
    categories {
      name
    }
    image: previewImage {
      fluid(quality: 90, maxWidth: 600, maxHeight: 390) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
  }

  query ArticleBySlug($slug: String!, $category: String, $type: String!) {
    contentfulArticles(slug: { eq: $slug }) {
      id
      name
      headline
      slug
      type
      date: publishDate(formatString: "MMMM YYYY")
      publishDate
      description {
        description
      }

      content {
        raw
        references {
          ...RichTextReferences
        }
      }
      hero {
        caption
        image {
          fluid(quality: 90, maxWidth: 1440) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      image: previewImage {
        fluid(quality: 90, maxWidth: 600, maxHeight: 390) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
        description
      }
      categories {
        name
        headline
        hasPage
      }
      relatedArticles {
        ...RelatedArticle
      }
      structuredData {
        headline
        image {
          fluid(quality: 90, maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        description {
          description
        }
        altText
      }
      author {
        name
        role
        image {
          fluid(quality: 95, maxHeight: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    fallbackRelatedArticles: allContentfulArticles(
      filter: {
        node_locale: { eq: "en-US" }
        categories: { elemMatch: { name: { eq: $category } } }
        slug: { ne: $slug }
        type: { eq: $type }
      }
      limit: 3
      sort: { order: DESC, fields: publishDate }
    ) {
      edges {
        node {
          ...RelatedArticle
        }
      }
    }
  }
`
